<template>
  <v-container
    fluid
    class="white-background client-service-forms service-report-form"
    :class="{ 'pointer-events-none': serviceForm.is_filled }"
  >
    <div class="d-none">
      <v-text-field
        type="hidden"
        v-model="pageLoading"
        :rules="[validateRules.required(!pageLoading)]"
      ></v-text-field>
    </div>
    <v-row class="mx-4">
      <v-col md="12" class="pt-0">
        <v-layout>
          <v-flex md8>
            <table width="100%">
              <tr>
                <th class="px-4 py-0">Complaints (if any)</th>
              </tr>
              <tr>
                <td>
                  <v-textarea
                    v-model="attributes.complaints"
                    auto-grow
                    dense
                    filled
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    placeholder="Complaints (if any)"
                    hide-details
                    solo
                    flat
                    row-height="35"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th class="px-4 py-0">Work Performed / Fault Found</th>
              </tr>
              <tr>
                <td>
                  <v-textarea
                    v-model="attributes.fault_found"
                    auto-grow
                    dense
                    filled
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    placeholder="Work Performed / Fault Found"
                    hide-details
                    solo
                    flat
                    row-height="35"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th class="px-4 py-0">
                  Follow-up Action / Recommendation / Remarks
                </th>
              </tr>
              <tr>
                <td>
                  <v-textarea
                    v-model="attributes.follow_up_remark"
                    auto-grow
                    dense
                    filled
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    placeholder="Follow-up Action / Recommendation / Remarks"
                    hide-details
                    solo
                    flat
                    row-height="35"
                  ></v-textarea>
                </td>
              </tr>
            </table>
          </v-flex>
          <v-flex md4>
            <table width="100%">
              <tr>
                <th class="px-4 py-0">Type of Equipment</th>
              </tr>
              <tr
                v-for="(row, index) in attributes.types_of_equipment"
                :key="index"
              >
                <td>
                  <v-checkbox
                    color="cyan"
                    dense
                    v-model="row.isChecked"
                    hide-details
                    :label="row.name"
                  ></v-checkbox>
                </td>
              </tr>
            </table>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  name: "service-report",
  mixins: [ValidationMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      pageLoading: true,
      attributes: {
        complaints: null,
        equip_other: null,
        fault_found: null,
        follow_up_remark: null,
        types_of_equipment: [
          {
            name: "Air Handling Unit",
            value: null,
            isChecked: false,
          },
          {
            name: "BMS/BAS",
            value: null,
            isChecked: false,
          },
          {
            name: "Chiller",
            value: null,
            isChecked: false,
          },
          {
            name: "Cooling Tower",
            value: null,
            isChecked: false,
          },
          {
            name: "DX System",
            value: null,
            isChecked: false,
          },
          {
            name: "Fan Coil Unit",
            value: null,
            isChecked: false,
          },
          {
            name: "FFU",
            value: null,
            isChecked: false,
          },
          {
            name: "Genset",
            value: null,
            isChecked: false,
          },
          {
            name: "HRU",
            value: null,
            isChecked: false,
          },
          {
            name: "MV Fans",
            value: null,
            isChecked: false,
          },
          {
            name: "Pipe Works",
            value: null,
            isChecked: false,
          },
          {
            name: "Pumps",
            value: null,
            isChecked: false,
          },
          {
            name: "UPS",
            value: null,
            isChecked: false,
          },
          {
            name: "Others",
            value: null,
            isChecked: false,
          },
        ],
      },
    };
  },
  methods: {
    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.attributes = _this.serviceForm.attributes;
          }
        }
      } catch (error) {
        _this.logError(error);
      } finally {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: _this.attributes, action },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
  },
  mounted() {
    const _this = this;

    ServiceFormEventBus.$on("start:save-service-form", (param) => {
      _this.saveServiceForm(param);
    });

    _this.updatePreInformation();
  },
};
</script>
