import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { QUERY } from "@/core/services/store/request.module";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
export default {
  mixins: [CommonMixin],
  data: function data() {
    return {
      invoiceLoading: false,
      rowData: [],
      currentPage: 1,
      status: "all",
      search: null,
      totalPages: 0,
      totalRows: 0,
      rowsOffset: 0
    };
  },
  props: {
    type: {
      type: String,
      default: null
    },
    typeId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    typeId: function typeId() {
      var _this = this;

      this.$nextTick(function () {
        _this.getInvoices();
      });
    }
  },
  methods: {
    clearFilter: function clearFilter() {//
    },
    getInvoices: function getInvoices() {
      var _this2 = this;

      var data = {
        current_page: this.currentPage,
        entity: 0,
        search: this.search,
        status: this.status,
        type: this.type,
        type_id: this.typeId
      };

      if (this.invoiceLoading) {
        return false;
      }

      this.invoiceLoading = true;
      this.$store.dispatch(QUERY, {
        url: "invoice",
        data: data
      }).then(function (_ref) {
        var data = _ref.data;
        _this2.totalPages = Math.ceil(data.total_rows / data.per_page);
        _this2.currentPage = data.current_page;
        _this2.rowData = data.rows;
        _this2.totalRows = data.total_rows;
        _this2.rowsOffset = data.offset;
      }).catch(function (error) {
        _this2.logError(error);
      }).finally(function () {
        _this2.invoiceLoading = false;
      });
    },
    routeToInvoice: function routeToInvoice(row) {
      var _this3 = this;

      this.$nextTick(function () {
        _this3.$router.push(_this3.getDefaultRoute("invoice.detail", {
          params: {
            id: row.id
          }
        }));
      });
    }
  },
  computed: {
    showingFrom: function showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false ? this.rowsOffset + 1 : 0;
    },
    showingTo: function showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    }
  },
  mounted: function mounted() {
    this.getInvoices();
  },
  components: {
    Barcode: Barcode,
    TableActivity: TableActivity,
    CustomStatus: CustomStatus,
    ListingFooter: ListingFooter,
    ListingTable: ListingTable
  }
};